import '../styles/globals.css';
import type { AppProps } from 'next/app';
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';
import { DefaultSeo } from 'next-seo';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <DefaultSeo
        title="Meet Conference app"
        titleTemplate="%s"
        defaultTitle="Meet Conference app"
        description="Meet is an open source."
        additionalMetaTags={[
          {
            property: 'theme-color',
            content: '#070707',
          },
        ]}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: 'https://digitalsiber.id/wp-content/uploads/2019/08/cropped-fav-32x32.png',
          },
          {
            rel: 'apple-touch-icon',
            href: 'https://digitalsiber.id/wp-content/uploads/2019/08/cropped-fav-180x180.png',
            sizes: '180x180',
          },
          {
            rel: 'mask-icon',
            href: '/images/livekit-safari-pinned-tab.svg',
            color: '#070707',
          },
        ]}
      />
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
